import axios from "@axios";

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchLoginLogs(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get("login-logs", { params: queryParams })
                    .then((response) => {       
                        resolve(response);
                    })
                    .catch((error) => reject(error));
            });
        },
    }
};
