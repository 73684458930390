<template>
  <div>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0 mt-2">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              :searchable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="SearchIcon" />
                </b-input-group-prepend>
                <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  placeholder="Search To Employee Name"
                />
              </b-input-group>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-table
    ref="refUserListTable"
    class="position-relative"
    :items="fetchLoginLogsList"
    responsive
    :fields="tableColumns"
    primary-key="hashid"
    :sort-by.sync="sortBy"
    show-empty
    empty-text="No matching records found"
    :sort-desc.sync="isSortDirDesc"
  >
    <template #cell(employee_name)="data">
      <b-media vertical-align="center">
        <div class="media-body">
          <b-link
            class="font-weight-bold d-inline-block text-nowrap"
            v-b-tooltip.hover.v-default
            :title="data.item.name.name"
            :href="'/profile/' + data.item.name.hashid"
            target="_blank"
          >
            {{ data.item.name.name }}
          </b-link>
        </div>
      </b-media>
    </template>
    <template #cell(email)="data">
      {{ data.item.name.email }}
    </template>

    <template #cell(date)="data">
      {{ data.item.date }}
    </template>

    <template #cell(time)="data">
      {{ data.item.time }}
    </template>

    <template #cell(device_type)="data">
      {{ data.item.device_type }}
    </template>
  </b-table>
      
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">
              Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries
            </span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalLoginLogList"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>


<script>
import {
  BButton,
  BCard,
  BCol,
  BFormInput,
  BLink,
  BMedia,
  BPagination,
  BRow,
  BTable,
  VBTooltip,
  BFormCheckbox,
  BFormCheckboxGroup,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { onUnmounted, watch, ref } from "@vue/composition-api";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import LoginLogStoreModule from "../LoginLogStoreModule";
import useLoginLogsList from "./useLoginLogsList";

export default {
  components: {
    // VoiceCallLogListFilters,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BLink,
    BPagination,
    AppCollapse,
    AppCollapseItem,
    vSelect,
    /* eslint-disable */
    ToastificationContent,
    /* eslint-enable */
    BFormCheckbox,
    BFormCheckboxGroup,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  setup() {
    const REFERRAL_APP_STORE_MODULE_NAME = "app-login-logs";

    // Register module
    if (!store.hasModule(REFERRAL_APP_STORE_MODULE_NAME)) {
      store.registerModule(
        REFERRAL_APP_STORE_MODULE_NAME,
        LoginLogStoreModule
      );
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(REFERRAL_APP_STORE_MODULE_NAME))
        store.unregisterModule(REFERRAL_APP_STORE_MODULE_NAME);
    });

    const statusOptions = [
      { label: "In Progress", code: 0 },
      { label: "Selected", code: 1 },
      { label: "Rejected", code: 2 },
    ];
    const {
      fetchLoginLogsList,
      tableColumns,
      perPage,
      currentPage,
      totalLoginLogList,
      loginLogStatusOption,
      loginLogsMetaData,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      statusFilter,

    } = useLoginLogsList();

    return {
      // Sidebar
      fetchLoginLogsList,
      tableColumns,
      perPage,
      currentPage,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      totalLoginLogList,
      statusOptions,
      statusFilter,
      loginLogStatusOption,
      loginLogsMetaData,
    };
  },
  data() {
    return {};
  },
  created() {},
  methods: {
    openWindow(link) {
      window.open(link);
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
<!-- ./useLoginLogsList../LoginLogStoreModule -->